<header class="px-3 py-2 bg-dark text-white">
    <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <a href="/" class="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
            <i class="bi bi-cpu" style="font-size: 2rem;"></i>
        </a>
  
        <ul class="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
          <li>
            <a href="#" class="nav-link text-secondary">
                <i class="bi bi-house d-block mx-auto mb-1" style="font-size: 1.5rem;"></i>
                Home
            </a>
          </li>
          <li>
            <a href="#" class="nav-link text-white">
                <i class="bi bi-controller d-block mx-auto mb-1" style="font-size: 1.5rem;"></i>
                Games
            </a>
          </li>
          <li>
            <a href="#" class="nav-link text-white">
                <i class="bi bi-kanban d-block mx-auto mb-1" style="font-size: 1.5rem;"></i>
                Projects
            </a>
          </li>
          <li>
            <a href="#" class="nav-link text-white">
                <i class="bi bi-camera-video d-block mx-auto mb-1" style="font-size: 1.5rem;"></i>
                Videos
            </a>
          </li>
          <li>
            <a href="#" class="nav-link text-white">
                <i class="bi bi-person d-block mx-auto mb-1" style="font-size: 1.5rem;"></i>
                Contact
            </a>
          </li>
        </ul>
      </div>
    </div>
  </header>